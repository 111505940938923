export const fairhopePhotos = [
    {
      src: require("../assets/images/fairhope/fairhope.jpg"),
      width: 4,
      height: 3
    },
    {
      src: require("../assets/images/fairhope/dock.jpg"),
      width: 1.5,
      height: 1
    },
    {
      src: require("../assets/images/fairhope/local_area.jpg"),
      width: 3,
      height: 3
    },
    {
      src: require("../assets/images/fairhope/local_area1.jpg"),
      width: 3,
      height: 4
    },
  ];

  export const propertyPhotos = [
    {
      src: require("../assets/images/property-v2/front-dusk.jpg"),
      width: 4.2,
      height: 2.8
    },
    {
      src: require("../assets/images/property/front_gate.jpg"),
      width: 10,
      height: 7
    },
    {
      src: require("../assets/images/property-v2/head-1.jpg"),
      width: 4.2,
      height: 2.8
    },
    {
      src: require("../assets/images/property-v2/court.jpg"),
      width: 4.2,
      height: 2.8
    },
    {
      src: require("../assets/images/property-v2/oven.jpg"),
      width: 4.2,
      height: 2.8
    },
    {
        src: require("../assets/images/property-v2/toss.jpg"),
        width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v3/sip-n-dip.jpg"),
      width: 2.4,
      height: 1.6
    },
    {
      src: require("../assets/images/property-v3/hot-tub-2.jpg"),
      width: 2.4,
      height: 1.6
    },
    {
      src: require("../assets/images/property-v3/golf-cart.jpg"),
      width: 4.2,
      height: 2.8
    },
    {
      src: require("../assets/images/property-v3/laundry-2.jpg"),
      width: 2.4,
      height: 1.6
    },
    {
      src: require("../assets/images/property-v3/laundry.jpg"),
      width: 2.4,
      height: 1.6
    },
    {
      src: require("../assets/images/property-v3/guest-bath.jpg"),
      width: 2.4,
      height: 1.6
    },

  ];

  export const pietMondrianPhotos = [
    {
      src: require("../assets/images/property-v2/pm (16).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (17).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (20).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (18).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (19).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (2).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (3).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
        src: require("../assets/images/property-v2/pm (4).jpg"),
        width: 4.2,
        height: 2.8
    },
    {
        src: require("../assets/images/property-v2/pm (5).jpg"),
        width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (8).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (9).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (10).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (11).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (12).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (13).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (14).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (15).jpg"),
      width: 4.2,
        height: 2.8
    },
    {
      src: require("../assets/images/property-v2/pm (1).jpg"),
      width: 4.2,
      height: 2.8
    },
  ];



  export const georgiaOKeeffePhotos = [
    {
      src: require("../assets/images/property-v2/gok (13).jpg"),
      width: 4.2,
      height: 2.8
    },
    {
      src: require("../assets/images/property-v2/gok (15).jpg"),
      width: 4.2,
      height: 2.8
    },
    {
      src: require("../assets/images/property-v2/gok (7).jpg"),
      width: 4.2,
      height: 2.8
  },
  {
      src: require("../assets/images/property-v2/gok (8).jpg"),
      width: 4.2,
      height: 2.8
  },
  {
    src: require("../assets/images/property-v2/gok (5).jpg"),
    width: 4.2,
    height: 2.8
  },
  {
    src: require("../assets/images/property-v2/gok (6).jpg"),
    width: 4.2,
    height: 2.8
  },
  
  {
      src: require("../assets/images/property-v2/gok (9).jpg"),
      width: 4.2,
      height: 2.8
  },
  {
    src: require("../assets/images/property-v2/gok (10).jpg"),
    width: 4.2,
    height: 2.8
  },
  {
      src: require("../assets/images/property-v2/gok (11).jpg"),
      width: 4.2,
      height: 2.8
  },
  {
    src: require("../assets/images/property-v2/gok (12).jpg"),
    width: 4.2,
    height: 2.8
  },
    {
      src: require("../assets/images/property-v2/gok (1).jpg"),
      width: 4.2,
      height: 2.8
    },
    {
      src: require("../assets/images/property-v2/gok (2).jpg"),
      width: 4.2,
      height: 2.8
    },
    {
      src: require("../assets/images/property-v2/gok (3).jpg"),
      width: 4.2,
      height: 2.8
    },
    {
      src: require("../assets/images/property-v2/gok (4).jpg"),
      width: 4.2,
      height: 2.8
    },

  ];

  export const hostPhotos = [
    {
      src: require("../assets/images/hosts/kay-tarrel.jpg"),
      width: 1.9,
      height: 1.2
    },
    {
      src: require("../assets/images/hosts/kay-tarrel-1.jpg"),
      width: 1.1,
      height: 1.7
    },
    {
      src: require("../assets/images/hosts/kay-tarrel-4.jpg"),
      width: 4,
      height: 3
    },
    {
      src: require("../assets/images/hosts/family.jpg"),
      width: 7.2,
      height: 9.6
    },
    {
      src: require("../assets/images/hosts/family-2.jpg"),
      width: 1,
      height: 1
    },
    {
      src: require("../assets/images/hosts/kay_tarrel.jpg"),
      width: 1,
      height: 1
    },
    {
      src: require("../assets/images/hosts/kay_and_tarrel.JPG"),
      width: 4,
      height: 3
    },
  ];

  